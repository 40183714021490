import React from 'react'

const ImageDiv = ({image}) => {
  return (
    <>
    <img src={image} style={{width: "100%", height: "100%"}} alt="image1" />
    </>
  )
}

export default ImageDiv