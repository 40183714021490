import React from "react";
// Icons
import profileImg from "../assets/images/user.jpg";
import { Avatar, Card, Image } from "@nextui-org/react";
import programming from "../assets/images/programmer.png"
import kumarImg from "../assets/images/kumar.jpeg"

const Leadership = () => {
  return (
    <div className="container py-5">
      <h1 style={{ color: "#1D1D1F", fontSize: "2.5rem" }}>Our Pillars</h1>
      <div className="row mainMobile mt-4">
        <div className="col-lg-4 mb-4">
          <Card isHoverable className="leadCard text-center">
            <Image
              showSkeleton
              maxDelay={1000}
              src={programming}
              alt="Default Image"
            />
            <h3 className="mt-3">Gaurav Karamchandani.</h3>
            <p>Managing Director</p>
          </Card>
        </div>
        <div className="col-lg-4 mb-4">
          <Card isHoverable className="leadCard text-center">
            <Image
              showSkeleton
              maxDelay={1000}
              src={kumarImg}
              alt="Default Image"
            />
            <h3 className="mt-3">Kumar Karamchandani.</h3>
            <p>Director</p>
          </Card>
        </div>
        <div className="col-lg-4 mb-4">
          <Card isHoverable className="leadCard text-center">
            <Image
              showSkeleton
              maxDelay={1000}
              src={programming}
              alt="Default Image"
            />
            <h3 className="mt-3">Pratap Hajari.</h3>
            <p>CTO</p>
          </Card>
        </div>
        <div className="col-lg-4 mb-4">
          <Card isHoverable className="leadCard text-center">
            <Image
              showSkeleton
              maxDelay={1000}
              src={programming}
              alt="Default Image"
            />
            <h3 className="mt-3">Sakshi Ursal.</h3>
            <p>CEO</p>
          </Card>
        </div>
        <div className="col-lg-4 mb-4">
          <Card isHoverable className="leadCard text-center">
            <Image
              showSkeleton
              maxDelay={1000}
              src={programming}
              alt="Default Image"
            />
            <h3 className="mt-3">Gorakh Bhosale.</h3>
            <p>COO</p>
          </Card>
        </div>
        <div className="col-lg-4 mb-4">
          <Card isHoverable className="leadCard text-center">
            <Image
              showSkeleton
              maxDelay={1000}
              src={programming}
              alt="Default Image"
            />
            <h3 className="mt-3">Sachin Jadhav.</h3>
            <p>CFO</p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Leadership;
