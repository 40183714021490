import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from "../assets/images/rightBack.svg";
import leftArrow from "../assets/images/leftBlack.svg";
// Icons
import orbitScanner from "../assets/images/Add_a_heading-removebg-preview.png"
import vivan from "../assets/images/Vivan.png"
import pluto from "../assets/images/Pluto.png"
import velocity from "../assets/images/Velocity.png"
import matrix from "../assets/images/Matrix.png"
import atlantic from "../assets/images/Atlantic.png"

const ProdSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    centerPadding: '60px',
    nextArrow: <img src={rightArrow} alt="left" />,
    prevArrow: <img src={leftArrow} alt="right" />,
  };
  return (
    <div className="container py-5 Prod">
        <h1 style={{color: "#1D1D1F", margin: "0", fontSize: "2.2rem"}}>Our Products</h1>
        <p style={{marginBottom:" 20px", color: "#8D8D92", fontSize: "1.2rem"}}>Building extraordinary products for an extraordinary nation...</p>
      <div className="row">
        <div className="col-lg-12">
          <Slider {...settings}>
            <div className="card sliderCard " >
                <img src={orbitScanner} alt="orbitScanner" style={{width: "15rem", alignSelf:"end"}} />
                <div className="bottomDiv">
                    <p className="title">Faster.Smarter.Better</p>
                </div>
            </div>
            <div className="card sliderCard ">
            <img src={vivan} alt="vivan" style={{width: "15rem", alignSelf:"end"}} />
                <div className="bottomDiv">
                    <p className="title">Performance you're looking for...</p>
                </div>
            </div>
            <div className="card sliderCard ">
            <img src={pluto} alt="pluto" style={{width: "15rem", alignSelf:"end"}} />
                <div className="bottomDiv">
                    <p className="title">From anywhere to everywhere.</p>
                </div>
            </div>
            <div className="card sliderCard ">
            <img src={velocity} alt="velocity" style={{width: "15rem", alignSelf:"end"}} />
                <div className="bottomDiv">
                    <p className="title">World's fastest scanner, yet.</p>
                </div>
            </div>
            <div className="card sliderCard ">
            <img src={matrix} alt="matrix" style={{width: "15rem", alignSelf:"end"}} />
                <div className="bottomDiv">
                    <p className="title">Why walk when you can run...</p>
                </div>
            </div>
            <div className="card sliderCard ">
            <img src={atlantic} alt="atlantic" style={{width: "15rem", alignSelf:"end"}} />
                <div className="bottomDiv">
                    <p className="title">atlantic</p>
                </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ProdSlider;
