import { Button, Input, Spacer } from "@nextui-org/react";
import React from "react";

const ClientLogin = () => {
  return (
    <div className="container pt-5 pb-5">
      <div className="row">
        <div className="col-lg-12">
          <h1
            style={{
              color: "#1D1D1F",
              marginBottom: "5rem",
              fontSize: "2.2rem",
            }}
            className="text-center"
          >
            Client Login
          </h1>
          <div className="card loginCard">
            <Input clearable size="lg" labelPlaceholder="User Name" />
            <Spacer y={2} />
            <Input.Password size="lg" labelPlaceholder="Password" />
            <Spacer y={2} />
            <Button css={{color:"#ffffff", background:"#333333", boxShadow: "0 4px 14px 0 #757575"}} flat  size="lg" shadow ripple animated auto>
          Sign In
        </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientLogin;
