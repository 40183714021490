import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import MainNavbar from "./components/MainNavbar";
import MainHomepage from "./pages/MainHomepage";
import Leadership from "./pages/Leadership";
import ClientLogin from "./pages/ClientLogin";
import EmployeeLogin from "./pages/EmployeeLogin";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";

function App() {
  return (
    <>
      <BrowserRouter>
        <MainNavbar />
        <Routes>
          <Route path="/client-login" element={<ClientLogin />} />
          <Route path="/employee-login" element={<EmployeeLogin />} />
          <Route path="/leadership" element={<Leadership />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<MainHomepage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
