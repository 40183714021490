import React from "react";
import ImageDiv from "../components/ImageDiv";
import {
  Fullpage,
  FullpageSection,
  FullPageSections,
} from "@ap.cx/react-fullpage";
import image1 from "../assets/images/1.jpg";
import image2 from "../assets/images/2.jpg";
import image4 from "../assets/images/5.jpg";
import image6 from "../assets/images/7.jpg";
import ProdSlider from "../components/ProdSlider";
import IndustriesSlider from "../components/IndustriesSlider";
import videoUrl from "../assets/video/pageBack.mp4";

const MainHomepage = () => {
  return (
    <>
      <Fullpage>
        <FullPageSections>
          <FullpageSection
            style={{
              backgroundColor: "white",
              paddingTop: "2.875rem",
            }}
          >
            <ImageDiv image={image1} />
          </FullpageSection>

          <FullpageSection
            style={{
              backgroundColor: "#F5F5F7",
              paddingTop: "2.875rem",
            }}
          >
            <ProdSlider />
          </FullpageSection>
          <FullpageSection
            style={{
              backgroundColor: "#F5F5F7",
              paddingTop: "2.875rem",
            }}
          >
            <ImageDiv image={image4} />
          </FullpageSection>
          <FullpageSection
            style={{
              backgroundColor: "#F5F5F7",
              paddingTop: "2.875rem",
            }}
          >
            <IndustriesSlider />
          </FullpageSection>
          <FullpageSection
              style={{
                backgroundColor: "#004BAC",
                // paddingTop: "2.875rem",
                position: "relative"
              }}
            >
              {/* <ImageDiv image={image2} /> */}
              <video loop playsInline autoPlay muted style={{height: "100%", width: "100%", objectFit: "cover"}}>
                <source src={videoUrl} type="video/mp4" />
              </video>
              <h2 className="videoHeading">
                Innovation-led growth continues
              </h2>
            </FullpageSection>
          <FullpageSection
            style={{
              backgroundColor: "#004BAC",
              paddingTop: "2.875rem",
            }}
          >
            <ImageDiv image={image6} />
          </FullpageSection>
        </FullPageSections>
      </Fullpage>
    </>
  );
};

export default MainHomepage;
