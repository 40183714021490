import React from "react";
import { Navbar, Link, Text, Dropdown } from "@nextui-org/react";
import logo from "../assets/images/logo.png";
import userLogo from "../assets/images/user-regular.svg";
import { Link as ReactLink } from "react-router-dom";
import downArrow from "../assets/images/chevron-down-solid.svg";

const MainNavbar = () => {
  const collapseItems = [
    { name: "Home", link: "/" },
    { name: "Leadership", link: "/leadership" },
    { name: "Our Products", link: "#" },
    { name: "Our Services", link: "#" },
    { name: "Contact", link: "/contact" },
    // { name :"Matrix",
    //   link : "#"
    // },
    // { name :"Atlantic",
    //   link : "#"
    // },
  ];

  return (
    <Navbar variant="sticky" css={{ background: "#313132", padding: "8px 0" }}>
      <Navbar.Toggle showIn="xs" />
      <Navbar.Brand
        css={{
          "@xs": {
            w: "12%",
          },
        }}
      >
        <a href="/">
          <img src={logo} alt="logo" width="35px" />
        </a>
      </Navbar.Brand>
      <Navbar.Content hideIn="xs" css={{ color: "#D6D6D6" }}>
        <Navbar.Link>
          <ReactLink to="/">Home</ReactLink>
        </Navbar.Link>
        <Navbar.Link>
          <ReactLink to="/leadership">Leadership</ReactLink>
        </Navbar.Link>
        {/* <Dropdown isBordered>
          <Navbar.Item>
            <Dropdown.Button
              auto
              light
              css={{
                px: 0,
                dflex: "center",
                svg: { pe: "none" },
                color: "#D1D6D6",
              }}
              // iconRight={}
              ripple={false}
            >
              About
            </Dropdown.Button>
          </Navbar.Item>
          <Dropdown.Menu
            aria-label="ACME features"
            css={{
              $$dropdownMenuWidth: "340px",
              $$dropdownItemHeight: "50px",
              "& .nextui-dropdown-item": {
                py: "$3",
                // dropdown item left icon
                svg: {
                  color: "$secondary",
                  mr: "$4",
                },
                // dropdown item title
                "& .nextui-dropdown-item-content": {
                  w: "100%",
                  fontWeight: "$semibold",
                },
              },
            }}
          >
            <Dropdown.Item key="leadership" showFullDescription>
              <ReactLink to="/leadership">Leadership</ReactLink>
            </Dropdown.Item>
            <Dropdown.Item key="ourTeam" showFullDescription>
              Our Team
            </Dropdown.Item>
            <Dropdown.Item key="about" showFullDescription>
              About Us
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <Navbar.Link>
          <ReactLink to="/">Our Products</ReactLink>
        </Navbar.Link>
        <Navbar.Link>
          <ReactLink to="/">Our Services</ReactLink>
        </Navbar.Link>
        <Navbar.Link>
          <ReactLink to="/contact">Contact</ReactLink>
        </Navbar.Link>
      </Navbar.Content>
      <Navbar.Content
        css={{
          "@xs": {
            w: "12%",
            jc: "flex-end",
          },
        }}
      >
        <Dropdown placement="bottom-right">
          <Navbar.Item>
            <Dropdown.Trigger>
              <img src={userLogo} alt="" style={{ width: "20px" }} />
            </Dropdown.Trigger>
          </Navbar.Item>
          <Dropdown.Menu
            aria-label="User menu actions"
            color="primary"
            onAction={(actionKey) => console.log({ actionKey })}
          >
            <Dropdown.Item key="clientLogin">
              <ReactLink to="/client-login">Client Login</ReactLink>
            </Dropdown.Item>

            <Dropdown.Item key="analytics" withDivider>
              <ReactLink to="/employee-login">Employee Login</ReactLink>
            </Dropdown.Item>

            {/* <Dropdown.Item key="logout" withDivider color="error">
            Log Out
          </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Content>
      <Navbar.Collapse>
        {collapseItems.map((item, index) => (
          <Navbar.CollapseItem key={item} activeColor="secondary">
            <Link
              color="inherit"
              css={{
                minWidth: "100%",
              }}
              href={item.link}
            >
              {/* <ReactLink to={item.link}> */}
              {item.name}
              {/* </ReactLink> */}
            </Link>
          </Navbar.CollapseItem>
        ))}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MainNavbar;
