import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from "../assets/images/rightBack.svg";
import leftArrow from "../assets/images/leftBlack.svg";
// Icons

import healthcare from "../assets/images/Experties/healthcare.jpg";
import technologies from "../assets/images/Experties/technologies.png";
import automation from "../assets/images/Experties/automation.png";
import institutes from "../assets/images/Experties/institutes.png";
import capital from "../assets/images/Experties/capital.png";
import foundation from "../assets/images/Experties/foundation.png";
import estates from "../assets/images/Experties/estates.png";
import entertainment from "../assets/images/Experties/entertainment.png";

const IndustriesSlider = () => {
  var settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    centerPadding: "60px",
    nextArrow: <img src={rightArrow} alt="left" />,
    prevArrow: <img src={leftArrow} alt="right" />,
  };
  return (
    <div className="container py-5 expert">
      <h1
        style={{
          color: "#1D1D1F",
          margin: "0",
          fontSize: "2.2rem",
          marginBottom: "20px",
        }}
      >
        Our Subsidiaries
      </h1>
      <div className="row mb-3">
        <div className="col-lg-12">
          <Slider {...settings1}>
          <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={technologies}
                  alt="technologies"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h3>Wilaton Technologies</h3>
            </div>
            <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={healthcare}
                  alt="healthcare"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h3>Wilaton Healthcare</h3>
            </div>
            
            <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={automation}
                  alt="automation"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h4>Wilaton Industrial Automation</h4>
            </div>
            <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={institutes}
                  alt="institutes"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h3>Wilaton Institutes</h3>
            </div>
            <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={capital}
                  alt="capital"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h3>Wilaton Capital</h3>
            </div>
            <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={foundation}
                  alt="foundation"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h3>Wilaton Foundation</h3>
            </div>
            <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={estates}
                  alt="estates"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h3>Wilaton Estates</h3>
            </div>
            <div className="outerCardDiv">
              <div className="card indCard ">
                <img
                  src={entertainment}
                  alt="entertainment"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <h3>Wilaton Entertainment</h3>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default IndustriesSlider;
