import React from "react";
import { Card, Link, Input, Spacer, Textarea, Button } from "@nextui-org/react";
import puneImg from "../assets/images/puneImg.png";
import mumbaiImg from "../assets/images/gateway-of-india.png";
import hydrabadImg from "../assets/images/hyderabad-charminar.png"

const Contact = () => {
  return (
    <div className="container mainCon">
      <h1 style={{ fontSize: "2.2rem" }}>Reach us</h1>
      <div className="row ">
        <div className="col-lg-4 my-4">
          <Link href="https://goo.gl/maps/oU2mYKgDvw4tVxNn9" target="_blank">
            <Card isHoverable className="reachCard p-4 text-center">
              <div className="imgDiv">
                <img src={puneImg} alt="" />
              </div>
              <h2 className="my-4">Main Office :</h2>
              <h5 style={{ color: "#757575" }}>
                sr.no. 15, Prayatnasheel Bibewadi, Market yard, Pune 411037.
              </h5>
            </Card>
          </Link>
          <hr className="contactHr" />
        </div>
        <div className="col-lg-4 my-4">
          <Link href="https://goo.gl/maps/nSgWRagWKc7zqGj27" target="_blank">
            <Card isHoverable className="reachCard p-4 text-center">
              <div className="imgDiv">
                <img src={puneImg} alt="" />
              </div>
              <h2 className="my-4">Operational Office :</h2>
              <h5 style={{ color: "#757575" }}>
                Rajdhani complex, office no. 409, 4th floor, Near shankar
                maharaj math, Pune 411043.
              </h5>
            </Card>
          </Link>
          <hr className="contactHr" />
        </div>
        <div className="col-lg-4 my-4">
          <Link href="https://goo.gl/maps/11vW6qNkEqG8AGJG8" target="_blank">
            <Card isHoverable className="reachCard p-4 text-center">
              <div className="imgDiv1">
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={mumbaiImg}
                  alt=""
                />
              </div>
              <h2 className="my-4">Branch Office :</h2>
              <h5 style={{ color: "#757575" }}>
                Brahans Business Park, 303, off Mahakali Caves Rd, Andheri East,
                Mumbai, Maharashtra 400053.
              </h5>
            </Card>
          </Link>
          <hr className="contactHr" />
        </div>
        <div className="col-lg-4 my-4">
          <Link href="https://goo.gl/maps/atz3kw317osvuvD28" target="_blank">
            <Card isHoverable className="reachCard p-4 text-center">
              <div className="imgDiv1">
                <img
                  style={{ width: "100px", height: "100px" }}
                  src={hydrabadImg}
                  alt=""
                />
              </div>
              <h2 className="my-4">Branch Office :</h2>
              <h5 style={{ color: "#757575" }}>
              205, Building 1, PSR Prime Towers, Next to DLF Cyber City, Gacchibowli, Hyderabad, Telangana 500032.
              </h5>
            </Card>
          </Link>
          <hr className="contactHr" />
        </div>
      </div>
      <hr className="mainHr" />
      <div className="row mt-3">
        <h1 style={{ fontSize: "2.2rem" }}>Contact us</h1>
        <p>
          <a href="mailto:info@wilaton.com" style={{ color: "#6082B6" }}>
            info@wilaton.com
          </a>
          <a
            href="tel:+918080120199"
            style={{ display: "block", color: "#6082B6" }}
          >
            +91 8080120199
          </a>
        </p>
        <div className="col-lg-12 mt-4">
          <Card className="p-5">
            <div className="row contactForm">
              <div className="col-lg-4">
                <Input
                  width="100%"
                  clearable
                  size="lg"
                  labelPlaceholder="Name"
                />
              </div>

              <div className="col-lg-4">
                <Input
                  width="100%"
                  clearable
                  size="lg"
                  labelPlaceholder="Mobile Number"
                />
              </div>

              <div className="col-lg-4">
                <Input
                  width="100%"
                  clearable
                  size="lg"
                  labelPlaceholder="Email Id"
                />
              </div>

              <div className="col-lg-12 ">
                <Textarea
                  placeholder="Enter your message"
                  width="100%"
                  clearable
                  size="lg"
                />
              </div>
              <div className="col-lg-12 text-center">
                <Button
                  css={{
                    color: "#ffffff",
                    background: "#333333",
                    boxShadow: "0 4px 14px 0 #757575",
                  }}
                  flat
                  size="lg"
                  shadow
                  ripple
                  animated
                  auto
                >
                  Send
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Contact;
